import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typography from "../Typography/Typography";
import PrevIcon from "../../assets/images/left.svg";
import PrevIconHighlighted from "../../assets/images/left-highlighted.svg";
import NextIconHighlighted from "../../assets/images/right-highlighted.svg";
import ArroSliderNextWhite from "../../assets/images/testimonials/nexticonwhite.svg";
import ArroSliderPrev from "../../assets/images/testimonials/previcon.svg";
import ArroSliderPrevWhite from "../../assets/images/testimonials/previconwhite.svg";
import ArroSliderNext from "../../assets/images/testimonials/nexticon.svg";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import NextIcon from "../../assets/images/right.svg";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint
} from "../helper";
import Img from "gatsby-image";
const useStyles = makeStyles(theme => ({
  imageSliderWrapper: props => ({
    paddingBottom: props && props.isImageWithDetails && "120px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingBottom: props && props.isImageWithDetails && "0px"
    },
    "& .slick-arrow": {
      background: "#fff",
      border: "2px solid #CC4A01",
      boxShadow: "0px 6px 10px rgb(66 75 90 / 20%)",
      width: "45px",
      height: "45px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",

      "& > svg": {
        fill: " #CC4A01",
        width: "35px",
        height: "32px",
        transition: "all ease 0.4s"
      },
      "&:hover": {
        "& > svg": {
          fill: " #fff",
          transition: "all ease 0.4s"
        }
      }
    },
    "& .slick-slider > img": {
      position: "absolute",
      zIndex: 1,
      transform: "translate(-50%, -100%)",
      top: "calc(100% + 100px)",
      cursor: "pointer",
      transition: "all ease 0.5s"
    },
    "& .slick-prev": {
      top: "auto",
      bottom: "-124px",
      left: "auto",
      right: "320px",
      background: `#ffffff url(${ArroSliderPrev})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",

      transition: "all ease 0.5s",
      "&:before": {
        content: "none"
      },
      "&:hover": {
        backgroundImage: ``,
        background: `#CC4A01 url(${ArroSliderPrevWhite})`,

        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }
    },
    "& .slick-next": {
      top: "auto",
      bottom: "-124px",
      right: "248px",
      background: `#ffffff url(${ArroSliderNext})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",

      transition: "all ease 0.5s",
      "&:before": {
        content: "none"
      },
      "&:hover": {
        backgroundImage: ``,
        background: `#CC4A01 url(${ArroSliderNextWhite})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }
    }
  }),
  nextArrow: {
    left: "calc(50% + 38px)",
    transition: "all ease 0.5s"
  },
  prevArrow: {
    left: "calc(50% - 38px)",
    transition: "all ease 0.5s"
  },
  "& .slick-slide": props => ({
    marginLeft: props.isRelatedArticles ? "20px" : "0px"
  }),

  imageWrapper: props => ({
    display: props.isRelatedArticles ? " block" : "flex !important",
    justifyContent: "center",
    alignItems: "center",
    width: props.isRelatedArticles ? "325px" : "auto",
    height: props.isRelatedArticles ? "auto" : "110px",
    flexDirection: props && props.isImageWithDetails && "column",
    [theme.breakpoints.up(extraLargeDesktopViewBreakpoint)]: {
      width: props.isRelatedArticles ? "388px" : "160px",
      height: props.isRelatedArticles ? "auto" : "110px"
    },
    "& .gatsby-image-wrapper": {
      width: props.isRelatedArticles ? "100%" : "160px",
      height: props.isRelatedArticles ? "100%" : "110px"
    },
    "& img": {
      marginRight: "0",
      filter: props && !props.isImageWithDetails && "grayscale(100%)",
      opacity: props && !props.isImageWithDetails && ".4",
      paddingLeft: props && props.isImageWithDetails && "15px",
      paddingRight: props && props.isImageWithDetails && "15px",
      objectFit: props && !props.isImageWithDetails && "contain!important",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        paddingLeft: props && props.isImageWithDetails && "4px",
        paddingRight: props && props.isImageWithDetails && "4px"
      },
      "&:hover": {
        filter: "none",
        opacity: "1"
      }
    },
    "& h4": {
      marginTop: "32px",
      padding: "0px 20px"
    },
    "& h5": {
      marginTop: "8px",
      padding: "0px 20px"
    }
  }),
  description: {
    color: "rgba(0, 0, 0, 0.6)"
  }
}));

const ImageSlider = ({
  data,
  isImageWithDetails,
  render,
  customSettings,
  isRelatedArticles
}) => {
  const classes = useStyles({ isImageWithDetails, isRelatedArticles });
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const handleMarkerMouseOver = (event, direction) => {
    if (direction === "next") event.target.src = NextIconHighlighted;
    else event.target.src = PrevIconHighlighted;
  };

  const handleMarkerMouseOut = (event, direction) => {
    if (direction === "next") event.target.src = NextIcon;
    else event.target.src = PrevIcon;
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  }

  const NextArrow = props => {
    const classes = useStyles();
    const { className, style, onClick } = props;
    return (
      <img
        className={classes.nextArrow}
        src={NextIcon}
        onClick={onClick}
        onMouseOver={event => {
          handleMarkerMouseOver(event, "next");
        }}
        onMouseOut={event => {
          handleMarkerMouseOut(event, "next");
        }}
      />
    );
  };

  const PrevArrow = props => {
    const classes = useStyles();
    const { className, style, onClick } = props;
    return (
      <img
        className={classes.prevArrow}
        src={PrevIcon}
        onClick={onClick}
        onMouseOver={event => {
          handleMarkerMouseOver(event, "prev");
        }}
        onMouseOut={event => {
          handleMarkerMouseOut(event, "prev");
        }}
      />
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isRelatedArticles ? 5 : 5,
    slidesToScroll: isRelatedArticles ? 1 : 5,
    initialSlide: 0,
    autoplay: true,
    lazyLoad: true,
    centerMode: true,
    centerPadding: "165px 0 165px",
    nextArrow: isImageWithDetails && !isMobileView && <SampleNextArrow />,
    prevArrow: isImageWithDetails && !isMobileView && <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2300,
        settings: {
          slidesToShow: (isImageWithDetails && 4) || 5,
          slidesToScroll: (isImageWithDetails && 1) || 5,
          infinite: true,
          centerMode: true,
          centerPadding:
            (isImageWithDetails && "20px 0 20px") || "87.5px 0 87.5px"
        }
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: (isImageWithDetails && 4) || 5,
          slidesToScroll: (isImageWithDetails && 1) || 5,
          infinite: true,
          centerMode: true,
          centerPadding:
            (isImageWithDetails && "20px 0 20px") || "87.5px 0 87.5px"
        }
      },
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: (isImageWithDetails && 4) || 5,
          slidesToScroll: (isImageWithDetails && 1) || 5,
          infinite: true,
          centerMode: true,
          centerPadding:
            (isImageWithDetails && "20px 0 20px") || "87.5px 0 87.5px"
        }
      },
      {
        breakpoint: 1234,
        settings: {
          slidesToShow: (isImageWithDetails && 3) || 5,
          slidesToScroll: (isImageWithDetails && 1) || 5,
          infinite: true,
          centerMode: true,
          centerPadding:
            (isImageWithDetails && "20px 0 20px") || "87.5px 0 87.5px"
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
          centerPadding:
            (isImageWithDetails && "20px 0 20px") || "87.5px 0 87.5px"
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding:
            (isImageWithDetails && "20px 0 20px") || "87.5px 0 87.5px"
        }
      }
    ]
  };

  let customSlides = null;

  if (render) {
    customSlides = render();
    customSettings.nextArrow = settings.nextArrow;
    customSettings.prevArrow = settings.prevArrow;
  }

  return (
    <div className={classes.imageSliderWrapper}>
      <Slider {...(customSettings || settings)}>
        {customSlides ||
          data.map(obj => {
            return (
              <div>
                <div className={classes.imageWrapper}>
                  <GatsbyImage image={obj.picture} alt={obj.alt} />

                  {obj.name && (
                    <Typography customVariant={"h4Bold"}>{obj.name}</Typography>
                  )}
                  {obj.description && (
                    <Typography
                      customVariant={"h5Regular"}
                      className={classes.description}
                    >
                      {obj.description}
                    </Typography>
                  )}
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default ImageSlider;
